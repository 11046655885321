import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74c868f1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accounts card custom-card custom-card-padding" }
const _hoisted_2 = {
  key: 1,
  class: "active"
}
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "icon is-small" }
const _hoisted_5 = {
  key: 0,
  class: "notification is-danger is-light"
}
const _hoisted_6 = { class: "mb-4" }
const _hoisted_7 = { class: "mb-4" }
const _hoisted_8 = {
  key: 2,
  class: "notification is-default notification-no-accounts"
}
const _hoisted_9 = { class: "is-flex mt-3" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["onChange"]
const _hoisted_12 = {
  key: 3,
  class: "section-card"
}
const _hoisted_13 = { class: "custom-card-title title-card" }
const _hoisted_14 = {
  key: 2,
  class: "inactive section-card"
}
const _hoisted_15 = { class: "custom-card-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BankAccountItem = _resolveComponent("BankAccountItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.activeVirtualAccounts.length)
      ? (_openBlock(), _createBlock(_component_loading, {
          key: 0,
          active: _ctx.accountsLoading,
          "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accountsLoading) = $event)),
          "can-cancel": false,
          "is-full-page": false
        }, null, 8, ["active"]))
      : _createCommentVNode("", true),
    (!_ctx.accountsLoading || _ctx.activeVirtualAccounts.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.refreshBalanceAndTransactions && _ctx.refreshBalanceAndTransactions(...args))),
            title: _ctx.$gettext('Refresh balance and transaction list'),
            class: _normalizeClass(["button is-default is-pulled-right is-rounded refresh", { 'active-refresh-button': _ctx.accountsLoading }])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass({ hide: _ctx.accountsLoading })
            }, _toDisplayString(_ctx.$gettext("Refresh")), 3),
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_fa_icon, {
                class: _normalizeClass({ refreshing: _ctx.accountsLoading }),
                icon: "sync"
              }, null, 8, ["class"])
            ])
          ], 10, _hoisted_3),
          (_ctx.accountsLoadingErrors.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$gettext(
              "An unexpected issue occurred while loading your " +
                "wallet information. Sorry for the inconvenience."
            )), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$gettext(
              "You can try to refresh the page, if the issue " +
                "persists, you may want to contact your " +
                "administrator"
            )), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isWalletUploading)
            ? (_openBlock(), _createBlock(_component_loading, {
                key: 1,
                active: _ctx.isWalletUploading,
                "onUpdate:active": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isWalletUploading) = $event)),
                "can-cancel": false,
                "is-full-page": false
              }, null, 8, ["active"]))
            : (
          _ctx.accountsLoadingErrors.length == 0 && _ctx.totalAccountsLoaded === 0
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createTextVNode(_toDisplayString(_ctx.$gettext("You don't have any wallet yet,")) + " ", 1),
                  _createVNode(_component_router_link, { to: "/create-account" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$gettext("click here")), 1)
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$gettext("to create one")) + " ", 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getUnconfiguredBackends(), (backend) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (backend.startsWith('comchain:'))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createTextVNode(_toDisplayString(_ctx.$gettext("Or import an existing wallet")) + " ", 1),
                            _createElementVNode("input", {
                              type: "file",
                              onChange: (event) => _ctx.registerWalletHandle(event, backend),
                              style: {"display":"none"}
                            }, null, 40, _hoisted_11),
                            _createElementVNode("button", {
                              class: "button is-default is-pulled-right is-rounded",
                              id: "import-wallet",
                              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.triggerFileInput && _ctx.triggerFileInput(...args)))
                            }, _toDisplayString(_ctx.$gettext("Import")), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 256))
                ]))
              : (
          _ctx.activeVirtualAccounts.length + _ctx.unavailableVirtualAccounts.length !== 0
        )
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.$gettext("your accounts")), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unavailableVirtualAccounts, (a) => {
                      return (_openBlock(), _createBlock(_component_BankAccountItem, {
                        class: "mb-5",
                        account: a
                      }, {
                        name: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$gettext("Unavailable")), 1)
                        ]),
                        _: 2
                      }, 1032, ["account"]))
                    }), 256)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeVirtualAccountsMiddleware, (a) => {
                      return (_openBlock(), _createBlock(_component_BankAccountItem, {
                        class: _normalizeClass(["mb-5", { selected: a._obj.internalId === _ctx.account?._obj?.internalId }]),
                        onAccountSelected: ($event: any) => (_ctx.$emit('accountSelected', a)),
                        "show-actions": a._obj.internalId === _ctx.account?._obj?.internalId,
                        account: a,
                        showSubAccounts: "true",
                        onRefreshTransaction: _cache[4] || (_cache[4] = ($event: any) => (_ctx.refreshBalanceAndTransactions()))
                      }, {
                        name: _withCtx(() => [
                          _createTextVNode(_toDisplayString(a.name()), 1)
                        ]),
                        _: 2
                      }, 1032, ["class", "onAccountSelected", "show-actions", "account"]))
                    }), 256))
                  ]))
                : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.inactiveVirtualAccounts.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("h2", _hoisted_15, _toDisplayString(_ctx.$gettext("your pending accounts")), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$gettext(
            "The accounts listed below have been subjected to " +
              "a creation request. Once the creation request is" +
              " approved, these accounts will become usable."
          )), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inactiveVirtualAccounts, (account) => {
            return (_openBlock(), _createBlock(_component_BankAccountItem, { account: account }, {
              name: _withCtx(() => [
                _createTextVNode(_toDisplayString(account.name()), 1)
              ]),
              _: 2
            }, 1032, ["account"]))
          }), 256))
        ]))
      : _createCommentVNode("", true)
  ]))
}